const APP_LOADER = "[APP].LOADER";
const APP_CONFIG = "[APP].CONFIG";
const APP_PRODUCT_UPC = "[APP].PRODUCT_UPC";

export default {
	name: {
		APP_LOADER: APP_LOADER,
		APP_CONFIG: APP_CONFIG,
		APP_PRODUCT_UPC: APP_PRODUCT_UPC,
	},
	setLoader: (data) => {
		return {
			type: APP_LOADER,
			loader: data,
		};
	},
	setConfig: (data) => {
		return {
			type: APP_CONFIG,
			config: data,
		};
	},
	setProductUpc: (data) => {
		return {
			type: APP_PRODUCT_UPC,
			product_upc: data,
		};
	},
};
