import React from "react";
import "./App.scss";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import Loader from "./components/Loader";
import Router from "./navigations/Router";
import configureStore from "./stores";

import "react-toastify/dist/ReactToastify.css";
import "./configs/console";

const { store, persistor } = configureStore();

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={<Loader />} persistor={persistor}>
				<div className="App">
					<Router />
				</div>
			</PersistGate>
		</Provider>
	);
}

export default App;
