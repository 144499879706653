import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Image, Modal, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { FaSpinner, FaCheckCircle } from "react-icons/fa";
import { BiBarcodeReader } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";

import ShadowScrollbars from "../../assets/ShadowScrollbars";

import APP_ACTION from "../../stores/actions/app";
import CART_ACTION from "../../stores/actions/cart";
import * as General from "../../helpers/General";

import "./CartList.scss";

let delayTimer;
let delayTimerChoose;

function CartList({
	app,
	cart,
	data,
	loading,
	category,
	addItemToCart,
	setProductUpc,
	callbackSearch,
}) {
	const [visibleVariants, setVisibleVariants] = useState(false);
	const [dataFinal, setDataFinal] = useState([]);
	const [variants, setVariants] = useState([]);
	const [barcodeActive, setBarcodeActive] = useState(true);
	const [textSearch, setTextSearch] = useState("");
	const modalElm = useRef(null);
	const mount = useRef(null);

	const { added, product } = app.product_upc;

	useEffect(() => {
		mount.current = true;
		if (mount.current) setDataFinal(data);
	}, [data]);

	useEffect(() => {
		const scanProcessUpc = () => {
			let isUpc = Object.keys(product).length > 0;
			if (isUpc && barcodeActive) {
				if (added) {
					// prevent double add
					clearTimeout(delayTimerChoose);
					delayTimerChoose = setTimeout(() => {
						addToCart({ item: product });
						setProductUpc({
							...app.product_upc,
							added: false,
						});
					}, 100);
				}
			}
		};
		scanProcessUpc();
		return () => scanProcessUpc();
		// eslint-disable-next-line
	}, [added]);

	const addToCartSimple = ({ item }) => {
		const { items } = cart;
		let itemLength = Object.keys(items).length;
		let dataItems = {};
		if (itemLength < 1) {
			dataItems = {
				[item.prodId]: {
					name: item.prodName,
					sku: item.prodSku,
					price: parseFloat(item.prodFinalPrice),
					price_type: "finalprice",
					prices: item.price,
					discount: 0,
					unitqty: item.prodUnit,
					qty: 1,
				},
			};
		} else {
			let qty =
				items[item.prodId] !== undefined ? items[item.prodId].qty + 1 : 1;
			if (qty <= item.prodQty) {
				dataItems = {
					...items,
					[item.prodId]: {
						name: item.prodName,
						sku: item.prodSku,
						price: parseFloat(item.prodFinalPrice),
						price_type: "finalprice",
						prices: item.price,
						discount: 0,
						unitqty: item.prodUnit,
						qty: qty,
					},
				};
			} else {
				dataItems = { ...items };
				toast.error("Semua stok sudah di tambahkan");
			}
		}

		addItemToCart(dataItems);
	};

	const addToCartConfigurable = ({ item }) => {
		const { items } = cart;
		let itemLength = Object.keys(items).length;
		let dataItems = {};

		if (itemLength < 1) {
			dataItems = {
				[item.prodParentId + "_" + item.pAttrId]: {
					name: item.prodName,
					sku: item.prodParentSku + "-" + item.pAttrId,
					price: item.prodFinalPrice,
					price_type: "finalprice",
					prices: item.price,
					attrId: item.pAttrId,
					discount: 0,
					unitqty: item.prodUnit,
					qty: 1,
				},
			};
		} else {
			// prettier-ignore
			let qty = items[item.prodParentId + "_" + item.pAttrId] !== undefined ? items[item.prodParentId + "_" + item.pAttrId].qty + 1: 1;
			if (qty <= item.prodQty) {
				dataItems = {
					...items,
					[item.prodParentId + "_" + item.pAttrId]: {
						name: item.prodName,
						sku: item.prodParentSku + "-" + item.pAttrId,
						price: parseFloat(item.prodFinalPrice),
						price_type: "finalprice",
						prices: item.price,
						attrId: item.pAttrId,
						discount: 0,
						unitqty: item.prodUnit,
						qty,
					},
				};
			} else {
				dataItems = { ...items };
				toast.error("Semua stok sudah di tambahkan");
			}
		}
		addItemToCart(dataItems);
	};

	const addToCart = ({ item }) => {
		let isVariants = false;

		if (item.variants !== undefined) {
			isVariants = item.variants.length > 0;
			if (isVariants) {
				let dataVariants = item.variants;
				setVisibleVariants(true);
				setVariants(dataVariants);
			} else {
				// if only simple product
				let prodQty = parseFloat(item.prodQty);
				if (prodQty < 1) toast.error("Maaf stock produk sudah habis");
				else {
					addToCartSimple({ item });
				}
			}
		} else {
			let isChildVariant = item.isVariant;
			if (isChildVariant) {
				let prodQty = parseFloat(item.prodQty);
				if (prodQty < 1) toast.error("Maaf stock produk sudah habis");
				else {
					addToCartConfigurable({ item });
				}
			}
		}
	};

	const handleVariantsClose = () => {
		setVisibleVariants(false);
		setVariants([]);
	};

	const renderVariantItem = () => {
		return (
			<Row>
				{variants.map((item, index) => {
					return renderProductItem({ prefix: "variant", item, index });
				})}
			</Row>
		);
	};

	const renderModalVariants = () => {
		return (
			<Modal
				ref={modalElm}
				show={visibleVariants}
				onHide={handleVariantsClose}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Variants</Modal.Title>
				</Modal.Header>
				<Modal.Body>{renderVariantItem()}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleVariantsClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	const renderProductItem = ({ prefix, item, index }) => {
		let isVariants = false;
		if (item.variants !== undefined) isVariants = item.variants.length > 0;

		let prodImage = "./images/noimage.png";
		if (item.prodImage !== null) prodImage = item.prodImage;

		// prettier-ignore
		let variantLabelPrice = isVariants ? "Sesuai Jenis" : General.numberFormat(null, item.prodFinalPrice)
		return (
			<Col key={prefix + "-" + index} xs={12} md={4} className="ProductItem">
				<div
					className="Product-Item"
					onClick={() => addToCart({ item, index })}
				>
					<Image src={prodImage} fluid />
					<div className="Product-Item-Content">
						<h4>{item.prodName}</h4>
						<span>{variantLabelPrice}</span>
					</div>
					<i>
						<FaCheckCircle />
					</i>
				</div>
			</Col>
		);
	};

	const renderData = () => {
		if (loading) {
			return <FaSpinner size={32} className="spinner Loader-Spinner" />;
		} else if (dataFinal.length < 1) {
			return <div>Data not found</div>;
		} else {
			let maxHeight = window.innerHeight - 195;
			return (
				<ShadowScrollbars autoHide={true} style={{ height: maxHeight }}>
					<Row className="ProductGrid">
						{dataFinal.map((item, index) => {
							return renderProductItem({ prefix: "product", item, index });
						})}
					</Row>
				</ShadowScrollbars>
			);
		}
	};

	const eventCallback = (params) => {
		if (barcodeActive) {
			clearTimeout(delayTimer);
			delayTimer = setTimeout(() => {
				callbackSearch({ text: params.text, upc: true, search: false });
				setTextSearch("");
			}, 100);
		} else {
			callbackSearch({ text: params.text, upc: false, search: true });
		}
	};

	const setBarcodeStatus = () => {
		setBarcodeActive(!barcodeActive);
		setTextSearch("");
	};

	const searchOnchange = (e) => {
		let text = e.target.value;
		setTextSearch(text);
		eventCallback({ text });
	};

	const renderSearch = () => {
		return (
			<Col xs={12} md={7}>
				<Row>
					<Col xs={12} md={10} className="Search-Form">
						<Form.Control
							type="text"
							placeholder="Cari Produk"
							value={textSearch}
							onChange={searchOnchange}
						/>
					</Col>
					<Col xs={12} md={2} className="Barcode-Scan">
						<Button
							variant={barcodeActive ? "primary" : "outline-primary"}
							onClick={() => setBarcodeStatus()}
						>
							<BiBarcodeReader size={24} />
						</Button>
					</Col>
				</Row>
			</Col>
		);
	};

	return (
		<div className="List-Product">
			<Row className="List-HeadProduct">
				<Col xs={12} md={5} className="Title-Category">
					<h3>{category.catName}</h3>
				</Col>
				{renderSearch()}
			</Row>
			<div className="AreaProduct">{renderData()}</div>
			{renderModalVariants()}
			<ToastContainer />
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	addItemToCart: (data) => dispatch(CART_ACTION.setItems(data)),
	setProductUpc: (data) => dispatch(APP_ACTION.setProductUpc(data)),
});

const mapStateToProps = (state) => ({
	app: state.app,
	cart: state.cart,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
