import { useState, useEffect, useRef } from "react";
import Api from "../services";

function useAddon() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	let mount = useRef();

	useEffect(() => {
		mount.current = true;
		if (mount.current) request();
		return () => (mount.current = false);
	}, []);

	const request = () => {
		let config = {
			method: "POST",
			url: Api.routes.CONFIG_ADDON,
			data: {
				params: "poza",
			},
		};
		if (mount.current) setLoading(true);
		Api.service(config)
			.then((res) => {
				let data = res.data;
				if (mount.current) {
					setData(data);
					setLoading(false);
				}
			})
			.catch((err) => {
				let errResponse = err.response;
				if (errResponse !== undefined) {
					let data = errResponse.data;
					if (mount.current) {
						setError(data);
						setLoading(false);
					}
				}
			});
	};

	return { loading, data, error };
}

export default useAddon;
