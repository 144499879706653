const CART_ITEMS = "[CART].ITEMS";
const CART_ITEMS_PRINT = "[CART].ITEMS_PRINT";
const CART_DISCOUNT = "[CART].DISCOUNT";
const CART_PAYMENT_METHOD = "[CART].PAYMENT_METHOD";
const CART_MEMBER = "[CART].MEMBER";

export default {
    name: {
        CART_ITEMS: CART_ITEMS,
        CART_ITEMS_PRINT: CART_ITEMS_PRINT,
        CART_DISCOUNT: CART_DISCOUNT,
        CART_PAYMENT_METHOD: CART_PAYMENT_METHOD,
        CART_MEMBER: CART_MEMBER,
    },
    setItems: (data) => {
        return {
            type: CART_ITEMS,
            items: data,
        };
    },
    setItemsPrint: (data) => {
        return {
            type: CART_ITEMS_PRINT,
            items_print: data,
        };
    },
    setDiscount: (data) => {
        return {
            type: CART_DISCOUNT,
            discount: data,
        };
    },
    setPaymentMethod: (data) => {
        return {
            type: CART_PAYMENT_METHOD,
            payment_method: data,
        };
    },
    setMember: (data) => {
        return {
            type: CART_MEMBER,
            member: data,
        };
    },
};
