import React from 'react';
// import { 
//     Container,
//     Row,
//     Col
// } from 'react-bootstrap';
import Navbar from '../../components/Navbar';

import './Profile.scss';

function Transaction() {
  return (
    <div className="Transaction">
        <Navbar />
        Profile
    </div>
  );
}

export default Transaction;