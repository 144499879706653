import { useState, useEffect, useRef } from "react";
import Api from "../services";

let configParams = "sitename,";
configParams += "sitedescription,";
configParams += "siteaddress,";
configParams += "timezone,";
configParams += "defaultcurrency,";
configParams += "multiprice,";
configParams += "multipayment,";
configParams += "discountproduct,";
configParams += "paymentmethod,";
configParams += "allow_comma_quantity,";
configParams += "businesstype,";
configParams += "posfooter,";
configParams += "weblogo";

function useConfig() {
    const [configLoading, setLoading] = useState(false);
    const [configResponse, setConfigResponse] = useState(null);
    const [configError, setConfigError] = useState(null);

    let mount = useRef();

    useEffect(() => {
        mount.current = true;
        if (mount.current) request();
        return () => (mount.current = false);
    }, []);

    const request = () => {
        let config = {
            url: Api.routes.CONFIG,
            data: { params: configParams },
            method: "POST",
        };

        if (mount.current) setLoading(true);
        Api.service(config)
            .then((res) => {
                let data = res.data;

                if (mount.current) {
                    setConfigResponse(data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                let errResponse = err.response;
                if (errResponse !== undefined) {
                    let data = errResponse.data;
                    if (mount.current) {
                        setConfigError(data);
                        setLoading(false);
                    }
                }
            });
    };

    return { configLoading, configResponse, configError };
}

export default useConfig;
