import USER_ACTION from "../actions/user";

const initialState = {
	user: null,
};

const REDUCER_APP = (prevState = initialState, action) => {
	switch (action.type) {
		case USER_ACTION.name.USER_DATA:
			return {
				...prevState,
				user: action.user,
			};
		default:
			return prevState;
	}
};

export default REDUCER_APP;
