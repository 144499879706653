import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./reducers";

const persistConfig = {
    key: "primary",
    storage,
    blacklist: ["app"],
    whitelist: ["cart", "user", "order"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [];

export default () => {
    const store = createStore(
        persistedReducer,
        // prettier-ignore
        process.env.NODE_ENV === "production" ? applyMiddleware(...middleware) : composeEnhancers(applyMiddleware(...middleware))
    );
    let persistor = persistStore(store);
    return { store, persistor };
};
