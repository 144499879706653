export const paymentMethod = ({ payment_method }) => {
	let defaultChoose = "";
	let list = [];
	if (payment_method !== undefined) {
		let paymentSplit = payment_method.split(",");
		let defaultPayment = paymentSplit.filter((item) => {
			let defaultSplit = item.split(":");
			return item === defaultSplit[0] + ":default";
		});
		if (defaultPayment.length < 1) defaultChoose = payment_method[0];
		else {
			let defaultChooseSplit = defaultPayment[0]?.split(":");
			defaultChoose = defaultChooseSplit[0];
		}

		if (paymentSplit.length > 0) {
			for (let indexPayment in paymentSplit) {
				let itemPayment = paymentSplit[indexPayment];
				let itemReplace = itemPayment.replace(":default", "");
				list.push(itemReplace);
			}
		}
	}

	return {
		default: defaultChoose,
		list,
	};
};
