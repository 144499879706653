import React from "react";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import Storage from "../../helpers/Storage";
import { routes } from "../routes";

const RouteItem = ({ token, location, children }) => {
	if (token) return children;
	else
		return (
			<Redirect
				to={{
					pathname: "/",
					state: { from: location },
				}}
			/>
		);
};

const RoutePrivate = ({ children, ...rest }) => {
	let token = Storage.get(Storage.name.TOKEN);
	return (
		<Route
			{...rest}
			render={({ location }) => (
				<RouteItem token={token} location={location} children={children} />
			)}
		/>
	);
};

const RouteInitial = () => {
	return routes.map((item, index) => {
		let auth = item.auth !== undefined ? item.auth : false;
		let exact = item.exact !== undefined ? item.exact : false;
		let path = item.path;
		const RouteComponent = item.component;
		if (auth) {
			return (
				<RoutePrivate key={"route-" + index} path={path}>
					<RouteComponent />
				</RoutePrivate>
			);
		} else {
			return (
				<Route key={"route-" + index} exact={exact} path={path}>
					<RouteComponent />
				</Route>
			);
		}
	});
};

function App() {
	return (
		<Router>
			<Switch>
				<RouteInitial />
			</Switch>
		</Router>
	);
}

export default App;
