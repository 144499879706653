const prefixInit = "IDR ";

const toFixedFix = (n, prec) => {
    var k = Math.pow(10, prec);
    return "" + (Math.round(n * k) / k).toFixed(prec);
};

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDateNotif = (date) => {
    let x = date.split("-");
    let d = x[2].split(" ");
    let formatted_date = d[0] + "/" + x[1] + "/" + x[0];
    return formatted_date;
};

export const formatDateOrder = (date) => {
    let d = date.split(" ");
    return d[0];
};

export const formatCurrency = function ({
    prefix = "IDR",
    value,
    seperate = ",",
}) {
    if (value === undefined || value === null || value === "") return 0;

    if (prefix === null) prefix = "";
    var rupiah = "";
    var valueRev = value.toString().split("").reverse().join("");
    for (var i = 0; i < valueRev.length; i++)
        if (i % 3 === 0) rupiah += valueRev.substr(i, 3) + seperate;
    return (
        prefix +
        rupiah
            .split("", rupiah.length - 1)
            .reverse()
            .join("")
    );
};

export const numberClear = ({ prefix = "IDR", value, seperate = /,/g }) => {
    let number = 0;
    if (value !== null) {
        number = value
            .toString()
            .replace(seperate, "")
            .replace(prefix, "")
            .trim();
    }
    return parseInt(number);
};

export const numberFormat = (
    prefix = null,
    number,
    thousandSeparator,
    decimals,
    decPoint
) => {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep =
        typeof thousandSeparator === "undefined" ? "," : thousandSeparator;
    let dec = typeof decPoint === "undefined" ? "." : decPoint;
    let s = "";

    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");

    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }

    let newPrefix = prefix == null ? prefixInit : prefix;
    return newPrefix + s.join(dec);
};

export const convertAndRound = (number) => {
    let num = parseFloat(number);
    return Math.round(num) + "%";
};

export const shortenText = (text, limit = 40) => {
    if (text.length > limit) {
        return text.substring(0, limit - 1) + "...";
    } else {
        return text;
    }
};

export const ucwords = (value) => {
    let str = "";
    if (value) {
        str = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
    }
    return str;
};
