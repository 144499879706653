import { combineReducers } from "redux";

import app from "./app";
import cart from "./cart";
import user from "./user";
import order from "./order";

export default combineReducers({
	app,
	cart,
	user,
	order,
});
