import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { HiUser } from "react-icons/hi";
import Storage from "../../helpers/Storage";

// import Efund from "../Cart/efund";
// import CloseOrder from "../Cart/close-order";
import History from "../Cart/history";

import "./Navbar.scss";

const SwalReact = withReactContent(Swal);

const App = ({ user }) => {
	let history = useHistory();
	const nameUser = () => {
		let username = user.user.username
			.toLowerCase()
			.replace(
				/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g,
				function (letter) {
					return letter.toUpperCase();
				}
			);
		return (
			<span>
				<HiUser /> {username}
			</span>
		);
	};

	const signOut = () => {
		SwalReact.fire({
			title: "Keluar",
			text: "Apakah anda yakin ingin keluar?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Keluar",
			confirmButtonColor: "#ef6e6e",
			cancelButtonText: "Batal",
			cancelButtonColor: "#4798e8",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Storage.del(Storage.name.TOKEN);
				history.push("/");
				SwalReact.fire("Anda berhasil keluar!", "", "success");
			}
		});
	};

	return (
		<Navbar variant="dark" expand="lg">
			<Navbar.Brand as={Link} to="/">
				<b>POZA</b>
				<pre>by Shiza</pre>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="ml-auto">
					{/* <Efund /> */}
					<History />
					{/* <CloseOrder /> */}
					<NavDropdown
						className="ml-4"
						title={nameUser()}
						id="basic-nav-dropdown"
						alignRight
					>
						<NavDropdown.Item onClick={() => signOut()}>
							Keluar
						</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, null)(App);
