import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { FaSpinner, FaCashRegister } from "react-icons/fa";

import Loader from "../../components/Loader";
import StateEmpty from "../../components/StateEmpty";
import Storage from "../../helpers/Storage";
import Api from "../../services";

import USER_ACTION from "../../stores/actions/user";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as Colors from "../../assets/styles/colors";

import useAddon from "../../hooks/useAddon";

import "./Login.scss";

const SwalReact = withReactContent(Swal);

const Login = ({ setUser }) => {
	const { loading: loadingAddon, data: dataAddOn } = useAddon();

	let [loading, setLoading] = useState(false);
	let [stores, setStores] = useState([]);
	let [storeChoose, setStoreChoose] = useState({});
	let [username, setUsername] = useState("");
	let [password, setPassword] = useState("");

	let history = useHistory();
	let mount = useRef();

	useEffect(() => {
		mount.current = true;
		const getStoreList = async () => {
			try {
				let res = await Api.service({
					method: "GET",
					url: Api.routes.STORE_LIST,
				});

				if (res.data !== "") {
					let data = res.data.data;
					setStores(data);
					setStoreChoose({
						index: 0,
						store: data[0],
					});
				}
			} catch (err) {
				console.log(err.toString());
			}
		};

		getStoreList();
		return () => (mount.current = false);
	}, []);

	useEffect(() => {
		const checkLogin = () => {
			let token = Storage.get(Storage.name.TOKEN);
			if (token !== null) {
				setLoading(true);
				history.push("/transaction");
			}
		};

		if (mount.current) checkLogin();
	}, [history]);

	const authProcess = (e) => {
		setLoading(true);
		Api.service({
			method: "POST",
			url: Api.routes.SIGNIN,
			data: {
				user: username,
				pass: password,
				store: storeChoose.store.storeId,
			},
		})
			.then((res) => {
				if (res.data !== "") {
					let status = res.status;
					let message = res.message;
					if (status) {
						let data = res.data.data;
						let token = data.token;
						Storage.set(Storage.name.TOKEN, token);

						let dataFinalUser = {
							...data.user,
							store: storeChoose.store,
						};

						setUser(dataFinalUser);
						history.push("/transaction");
						SwalReact.fire({
							title: "Sukses login",
							text: message,
							icon: "success",
						});
					} else {
						toast.error(message);
					}
				}
				if (mount.current) setLoading(false);
			})
			.catch((err) => {
				if (err.response === undefined) {
					toast.error("Periksa kembali toko, username atau password anda");
				} else {
					let errResponse = err.response;
					if (errResponse !== undefined) {
						let data = errResponse.data;
						let message = data.message;
						toast.error(message);
					}
				}
				setLoading(false);
			});
		e.preventDefault();
	};

	const RenderStores = () => {
		if (stores.length < 1) {
			return <div>No store(s) found</div>;
		}
		return (
			<div>
				<select
					className="form-control"
					value={storeChoose.index}
					onChange={(e) => {
						let index = e.target.value;

						setStoreChoose({
							index,
							store: stores[index],
						});
					}}
				>
					{stores.map((item, index) => {
						return (
							<option key={"option-" + index} value={index}>
								{item.storeName}
							</option>
						);
					})}
				</select>
			</div>
		);
	};

	// checking if addon active
	if (loadingAddon || dataAddOn === null) return <Loader />;
	else if (!loadingAddon && dataAddOn.data.poza !== undefined) {
		const poza = dataAddOn.data.poza;
		let isActive = parseInt(poza.addonsActive);
		if (!isActive)
			return (
				<StateEmpty
					icon={<FaCashRegister size={100} color={Colors.GRAY_SMOOTH_2} />}
					label={"Poza unavailable, please activate the addon first."}
				/>
			);
	}

	return (
		<div className="Login-Frame">
			<div className="Login-Header">
				<h1 className="Login-Title">
					<b>POZA</b>
				</h1>
				<span className="Login-Title-Sub">
					by{" "}
					<a href="http://shiza.id" rel="noopener noreferrer" target="_blank">
						shiza.id
					</a>
				</span>
			</div>
			<div className="Login">
				{loading && (
					<div className="Loader">
						<FaSpinner size={32} className="spinner Loader-Spinner" />
						<p className="Loader-Info">Please wait a moment...</p>
					</div>
				)}
				{!loading && (
					<Form>
						<Form.Group controlId="formBasicStore">
							<Form.Label>Stores</Form.Label>
							{RenderStores()}
						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Username</Form.Label>
							<Form.Control
								type="text"
								placeholder="Masukkan username"
								onChange={(e) => setUsername(e.target.value)}
							/>
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Masukkan Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Group>

						<Button
							variant="primary"
							type="submit"
							onClick={(e) => authProcess(e)}
						>
							Login
						</Button>
					</Form>
				)}
			</div>
			<ToastContainer />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setUser: (data) => dispatch(USER_ACTION.setUser(data)),
});

const mapStateToProps = (state) => ({
	app: state.app,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
