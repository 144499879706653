const USER_DATA = "[USER].DATA";

export default {
	name: {
		USER_DATA: USER_DATA,
	},
	setUser: (data) => {
		return {
			type: USER_DATA,
			user: data,
		};
	},
};
