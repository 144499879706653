import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Button, Row, Col, Form, Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { FaUsers, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import Api from "../../services";
import Show from "../Show";
import Loader from "../Loader";
import CART_ACTION from "../../stores/actions/cart";

const Member = () => {
    const [showMembers, setShowMember] = useState(false);
    const [memberTemporary, setMemberTemporary] = useState([]);
    const [member, setMember] = useState([]);
    const [loading, setLoading] = useState(false);
    const cart = useSelector((state) => state.cart);
    const dataMember = cart?.member;
    const user = useSelector((state) => state.user);
    const storeId = user?.user?.store?.storeId;
    const dispatch = useDispatch();
    const mount = useRef();

    const getDataMembers = useCallback(async () => {
        try {
            setLoading(true);
            const res = await Api.service({
                method: "GET",
                url: Api.routes.MEMBER_LIST + "?storeId=" + storeId,
            });
            const data = res.data;
            const member = data.data;
            setMember(member);
            setMemberTemporary(member);
            setLoading(false);
        } catch (err) {
            console.log("[err] get data member", err);
            setLoading(false);
        }
    }, [storeId]);

    useEffect(() => {
        mount.current = true;
        if (mount.current) getDataMembers();
        return () => (mount.current = false);
    }, [getDataMembers]);

    const memberClose = () => setShowMember(false);
    const memberShow = () => setShowMember(true);
    const onFindMember = (input) => {
        const text = input.target.value;
        const filter = member?.filter((item) => {
            return (
                item?.mName?.toLowerCase().startsWith(text) ||
                item?.mHP?.startsWith("+62" + text)
            );
        });
        if (filter?.length < 1 || text === "") setMember(memberTemporary);
        else setMember(filter);
    };

    const onSelectMember = (data) => {
        dispatch(CART_ACTION.setMember(data));
        memberClose();
    };

    const onRemoveMember = (data) => {
        dispatch(CART_ACTION.setMember(null));
    };

    const renderModalMember = () => {
        const maxHeightPayment = window.innerHeight - 250;
        return (
            <>
                <Modal
                    show={showMembers}
                    onHide={memberClose}
                    className="MembersModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>List Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Scrollbars
                            autoHide={true}
                            style={{ height: maxHeightPayment }}
                        >
                            <Show when={loading}>
                                <Loader />
                            </Show>
                            <Show when={!loading && member?.length < 1}>
                                No data found
                            </Show>
                            <Show when={!loading && member?.length > 0}>
                                <Form.Control
                                    type="text"
                                    placeholder="Find Member"
                                    onChange={(text) => onFindMember(text)}
                                />
                                <Container className="MemberContainer">
                                    {member?.map((item, index) => {
                                        return (
                                            <Row
                                                key={"member-" + index}
                                                className="MemberItems"
                                            >
                                                <Col
                                                    xs={12}
                                                    md={12}
                                                    className="MemberItem"
                                                >
                                                    <Row>
                                                        <Col
                                                            xs={1}
                                                            md={1}
                                                            className="RadioMember"
                                                        >
                                                            <Form.Check.Input
                                                                type="radio"
                                                                name="member"
                                                                id={`radio-idmember`}
                                                                onChange={() => {}}
                                                                checked={
                                                                    item?.mId ===
                                                                    dataMember?.mId
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={11}
                                                            md={11}
                                                            className="RadioMemberLabel"
                                                            onClick={() =>
                                                                onSelectMember(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            <label htmlFor="radio-idmember">
                                                                <Row>
                                                                    <Col
                                                                        xs={7}
                                                                        md={9}
                                                                        className="MemberInfo"
                                                                    >
                                                                        {item.mName +
                                                                            " (" +
                                                                            item.mHP +
                                                                            ")"}
                                                                    </Col>
                                                                </Row>
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Container>
                            </Show>
                        </Scrollbars>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={memberClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div className="BtnMember">
                <div className="BtnMember-Button" onClick={memberShow}>
                    <span className="ChooseLogo">
                        <FaUsers />
                    </span>
                    {dataMember === null ? "Pilih member" : dataMember?.mName}
                </div>
                {dataMember !== null && (
                    <Button
                        style={{ marginLeft: 5, borderRadius: 20 }}
                        variant="danger"
                        size="sm"
                        onClick={() => onRemoveMember()}
                    >
                        <FaTimesCircle />
                    </Button>
                )}
            </div>
            {renderModalMember()}
        </>
    );
};

export default Member;
