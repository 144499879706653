export default {
    name: {
        TOKEN: 'TOKEN'
    },
    set: (name, data) => {
        localStorage.setItem(name, data);
    },
    get: (name) => {
        return localStorage.getItem(name);
    },
    del: (name) => {
        localStorage.removeItem(name);
    },
    clear: () => {
        localStorage.clear();
    }
}