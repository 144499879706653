import React, { useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import {
    FaPlusCircle,
    FaMinusCircle,
    FaPercent,
    FaChevronUp,
    FaChevronDown,
} from "react-icons/fa";
import { BsXCircle, BsInfoCircle } from "react-icons/bs";
import { Scrollbars } from "react-custom-scrollbars";
import { ToastContainer } from "react-toastify";

import PaymentModal from "./payment";
import DeleteConfirmModal from "./remove-product";
import Show from "../Show";
import Member from "./member";
import PlaceOrderModal from "./place-order";
import CART_ACTION from "../../stores/actions/cart";

import * as Colors from "../../assets/styles/colors";
import * as General from "../../helpers/General";
import * as ObjectHelper from "../../helpers/Object";

import "./style/Cart.scss";

const Discount = ({ subtotal, amount }) => {
    return (
        <div className="Cart-Sub-Total">
            <div className="Cart-SubTotal">
                SubTotal <span>{General.numberFormat(null, subtotal)}</span>
            </div>
            <Show when={amount}>
                <div className="Cart-Discount">
                    <div className="descDiscount">Diskon Total</div>
                    <span> - {General.numberFormat(null, amount)}</span>
                </div>{" "}
            </Show>
        </div>
    );
};

const Cart = ({
    app,
    cart,
    addItemToCart,
    callbackCart,
    setCartDiscount,
    setPaymentMethod,
}) => {
    const [renderPayment] = useState(false);
    const [discEnable, setDiscEnable] = useState({});
    const [totalDetailActive, setTotalDetailActive] = useState(false);
    const [payments, setPayments] = useState([]);
    let disc = cart.discount;

    useEffect(() => {
        const { config } = app;
        let payment_method = config?.paymentmethod;
        let getPaymentMethod = ObjectHelper.paymentMethod({ payment_method });
        setPayments(getPaymentMethod.list);
        setPaymentMethod({
            default: getPaymentMethod.default,
            choose: getPaymentMethod.default,
        });
    }, [app, setPaymentMethod]);

    const calculateDiscount = (discountProduct) => {
        let cartDiscount = General.numberClear({ value: disc });
        let discount = 0;
        if (disc < 1) {
            discount = discountProduct;
        } else if (discountProduct < 1) {
            discount = cartDiscount;
        } else {
            discount = cartDiscount + discountProduct;
        }

        return discount;
    };

    const getGrandTotal = () => {
        const { items } = cart;
        let subtotal = 0;
        let discountProduct = 0;
        for (let itemIndex in items) {
            let item = items[itemIndex];
            let total = item.qty * item.price;
            subtotal = subtotal + total;
            discountProduct = discountProduct + item.discount;
        }
        // prettier-ignore
        let finalDiscount = calculateDiscount(discountProduct);
        let grandtotal = subtotal - finalDiscount;
        return grandtotal;
    };

    const qtyValueCondition = (qty) => {
        const { config } = app;
        let qtyFinal = 0;
        let allow_comma_quantity = config?.allow_comma_quantity;
        let isAllow = allow_comma_quantity === "yes";
        if (isAllow) qtyFinal = qty;
        else qtyFinal = parseInt(qty);

        return qtyFinal;
    };

    const changeItemQty = ({ item, qty }) => {
        const { items } = cart;

        let finalQty = isNaN(qtyValueCondition(qty))
            ? 1
            : qtyValueCondition(qty);
        let dataItems = {
            ...items,
            [item.id]: {
                ...items[item.id],
                qty: finalQty,
            },
        };

        addItemToCart(dataItems);
    };

    const changeItemDiscount = ({ item, discount }) => {
        const { items } = cart;
        let finalDiscount = isNaN(discount) ? 0 : discount;
        let dataItems = {
            ...items,
            [item.id]: {
                ...items[item.id],
                discount: finalDiscount,
            },
        };
        addItemToCart(dataItems);
    };

    const clickPlusQty = ({ item }) => {
        const { items } = cart;
        let qty = parseInt(qtyValueCondition(items[item.id].qty)) + 1;
        let dataItems = {
            ...items,
            [item.id]: {
                ...items[item.id],
                qty: qty,
            },
        };

        addItemToCart(dataItems);
    };

    const clickMinusQty = ({ item }) => {
        const { items } = cart;
        let qty = parseInt(qtyValueCondition(items[item.id].qty)) - 1;
        let dataItems = {
            ...items,
            [item.id]: {
                ...items[item.id],
                qty: qty < 1 ? 1 : qty,
            },
        };

        addItemToCart(dataItems);
    };

    const callbackDelete = (data) => {
        const { items } = cart;
        let dataItems = { ...items };
        delete dataItems[data.id];

        addItemToCart(dataItems);
    };

    const discountChange = (e) => {
        let targetValue = e.target.value;
        let value = targetValue === "" ? 0 : targetValue;
        let discountValueFilter = General.numberClear({ value });
        setCartDiscount(discountValueFilter); // for struck.js
    };

    const clickButtonPercent = ({ index }) => {
        let toggleDisc = !discEnable[index];
        setDiscEnable({
            ...discEnable,
            [index]: toggleDisc,
        });
    };

    const onChangePrice = ({ cartIndex, value }) => {
        const { items } = cart;

        let valueSplit = value.split("|");
        let priceSlug = valueSplit[0];
        let priceValue = valueSplit[1];

        let dataItems = {
            ...items,
            [cartIndex]: {
                ...items[cartIndex],
                price: priceValue,
                price_type: priceSlug,
            },
        };

        addItemToCart(dataItems);
    };

    const renderMultiPriceOptions = ({ prices }) => {
        let pricesArr = [];
        if (prices === undefined) return null;

        let isNormalPrice = prices.normalprice === prices.finalprice;

        for (let indexPrice in prices) {
            let itemPrice = prices[indexPrice];
            let itemPriceValue = parseInt(itemPrice);
            if (itemPriceValue > 0) {
                if (isNormalPrice) {
                    // if final price same with normal price, only normal price will be show
                    if (indexPrice !== "finalprice") {
                        pricesArr.push(
                            <option
                                key={"price-" + indexPrice}
                                value={indexPrice + "|" + itemPrice}
                            >
                                {General.ucwords(indexPrice)}-
                                {General.numberFormat(null, itemPrice)}
                            </option>
                        );
                    }
                } else {
                    // if not all push
                    pricesArr.push(
                        <option key={"price-" + indexPrice} value={itemPrice}>
                            {General.ucwords(indexPrice)}-
                            {General.numberFormat(null, itemPrice)}
                        </option>
                    );
                }
            }
        }

        return pricesArr;
    };

    const renderPrePayment = () => {
        const { items } = cart;
        const { config } = app;

        let isAllowCommaQuantity = config?.allow_comma_quantity === "yes";
        let dataCart = [];
        let subtotal = 0;
        let discountProduct = 0;
        for (let itemIndex in items) {
            let item = items[itemIndex];
            let total = item.qty * item.price;
            let id = itemIndex;
            subtotal = subtotal + total;
            discountProduct = discountProduct + item.discount;
            dataCart.push({ ...item, id, total });
        }
        // prettier-ignore
        let discountAmount = calculateDiscount(discountProduct);
        let configDiscount = config?.discountproduct;
        let configMultiPrice = config?.multiprice;
        // prettier-ignore
        let maxHeightCart = totalDetailActive ?  window.innerHeight - 368 : window.innerHeight - 290;
        if (totalDetailActive && discountAmount)
            maxHeightCart = window.innerHeight - 398;
        // prettier-ignore
        let activeStyleDiscount = General.numberClear({ value: disc }) > 0 ? "discountBtnClick active" : "discountBtnClick";
        // prettier-ignore
        let btnClassDetail = totalDetailActive ? "btn btn-block btn-primary Cart-Payment-Btn-Up" : "btn btn-block btn-light Cart-Payment-Btn-Up";

        return (
            <>
                <Scrollbars style={{ height: maxHeightCart }}>
                    <Show when={isAllowCommaQuantity}>
                        <div className="col-12 mt-1">
                            <div className="alert alert-info text-center">
                                <BsInfoCircle /> Sistem mengijinkan kuantiti
                                menggunakan <strong>koma (.)</strong>
                                &nbsp;contoh: <strong>3.5</strong>
                            </div>
                        </div>
                    </Show>
                    <ul className="OrderDetail-Item">
                        {dataCart.map((item, index) => {
                            // prettier-ignore
                            let classActivePercent = discEnable[index] ? "discPercentDrop active" : "discPercentDrop";

                            let selectDefaultValue =
                                item.price_type + "|" + item.price;
                            return (
                                <li key={"cart-item-" + index}>
                                    <div className="NameCols">
                                        <DeleteConfirmModal
                                            callbackDelete={callbackDelete}
                                            item={item}
                                        />
                                        <div className="Cart-Item-Title-Price">
                                            <div className="CartName">
                                                {item.name}
                                            </div>
                                            <Show
                                                when={
                                                    configMultiPrice === "yes"
                                                }
                                            >
                                                <div className="Cart-Item-Subtotals MultiPrice">
                                                    <select
                                                        className="form-control"
                                                        value={
                                                            selectDefaultValue
                                                        }
                                                        onChange={(e) => {
                                                            let value =
                                                                e.target.value;
                                                            onChangePrice({
                                                                cartIndex:
                                                                    item.id,
                                                                value,
                                                            });
                                                        }}
                                                    >
                                                        {renderMultiPriceOptions(
                                                            {
                                                                prices: item.prices,
                                                            }
                                                        )}
                                                    </select>
                                                </div>
                                            </Show>
                                            <Show
                                                when={
                                                    configMultiPrice !== "yes"
                                                }
                                            >
                                                <div className="Cart-Item-Subtotals">
                                                    {General.numberFormat(
                                                        null,
                                                        item.total
                                                    )}
                                                </div>
                                            </Show>
                                        </div>
                                    </div>
                                    <div className="qtyCols">
                                        <div className="Cart-Item-Qty">
                                            <span
                                                className="MinusQty"
                                                onClick={() =>
                                                    clickMinusQty({
                                                        item,
                                                        index,
                                                    })
                                                }
                                            >
                                                <FaMinusCircle />
                                            </span>
                                            <input
                                                type="text"
                                                value={item.qty}
                                                className="Input-Qty"
                                                onChange={(e) => {
                                                    let qty = e.target.value;
                                                    changeItemQty({
                                                        item,
                                                        index,
                                                        qty,
                                                    });
                                                }}
                                            />
                                            <span
                                                className="plusQty"
                                                onClick={() =>
                                                    clickPlusQty({
                                                        item,
                                                        index,
                                                    })
                                                }
                                            >
                                                <FaPlusCircle />
                                            </span>
                                            <Show
                                                when={configDiscount === "yes"}
                                            >
                                                <div
                                                    className={
                                                        classActivePercent
                                                    }
                                                    onClick={() =>
                                                        clickButtonPercent({
                                                            index,
                                                        })
                                                    }
                                                >
                                                    <FaPercent />
                                                </div>
                                            </Show>
                                        </div>
                                    </div>
                                    <Show when={discEnable[index]}>
                                        <div className="CartDiskon">
                                            <InputGroup className="mb-2 mr-sm-2">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        Diskon
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    value={item.discount}
                                                    className="Input-Discount col-6 form-control"
                                                    onChange={(e) => {
                                                        let discount = parseInt(
                                                            e.target.value
                                                        );
                                                        changeItemDiscount({
                                                            item,
                                                            index,
                                                            discount,
                                                        });
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Show>
                                </li>
                            );
                        })}
                    </ul>
                </Scrollbars>
                <hr />
                <Show when={totalDetailActive}>
                    <div className="Cart-Grand-Total">
                        <div className={activeStyleDiscount}>
                            <span>Diskon Keranjang </span>
                            <input
                                type="text"
                                className="form-control discInputs"
                                placeholder="Tambahkan diskon"
                                value={General.numberFormat("", disc)}
                                onChange={discountChange}
                            />
                        </div>
                    </div>
                    <Discount subtotal={subtotal} amount={discountAmount} />
                </Show>
                <Row className="summaryRow">
                    <Col xs={12} md={2} className="Cart-Payment">
                        <button
                            className={btnClassDetail}
                            onClick={() =>
                                setTotalDetailActive(!totalDetailActive)
                            }
                        >
                            <Show when={!totalDetailActive}>
                                <FaChevronUp size={18} />
                            </Show>
                            <Show when={totalDetailActive}>
                                <FaChevronDown size={18} />
                            </Show>
                        </button>
                    </Col>
                    <Col xs={12} md={4} className="Cart-Payment">
                        <PaymentModal paymentMethod={payments} />
                    </Col>
                    <Col xs={12} md={6} className="Cart-Grand-Button-Payment">
                        <PlaceOrderModal
                            grandTotal={getGrandTotal()}
                            callbackCart={callbackCart}
                            discount={disc}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    const renderCartItems = () => {
        const { items } = cart;
        let itemLength = Object.keys(items).length;
        if (itemLength < 1)
            return <div className="empty">Tidak ada barang di keranjang</div>;
        else {
            if (!renderPayment) return renderPrePayment();
        }
    };

    const renderListItems = () => {
        const { items } = cart;
        let itemLength = Object.keys(items).length;
        if (itemLength < 1)
            return (
                <div className="empty mt-5">
                    <div>
                        <BsXCircle size={70} color={Colors.GRAY_SMOOTH_2} />
                    </div>
                    <div className="mt-2 CartNoData">
                        Tidak ada barang di keranjang
                    </div>
                </div>
            );
        return renderCartItems();
    };

    return (
        <div className="CartContainer">
            <div className="CartTitle">
                Keranjang Pesanan
                <span className="OrderDate"> {new Date().toDateString()}</span>
                <Member />
            </div>
            <div className="OrderDetails">{renderListItems()}</div>
            <ToastContainer />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addItemToCart: (data) => dispatch(CART_ACTION.setItems(data)),
    setCartDiscount: (data) => dispatch(CART_ACTION.setDiscount(data)),
    setPaymentMethod: (data) => dispatch(CART_ACTION.setPaymentMethod(data)),
});

const mapStateToProps = (state) => ({
    cart: state.cart,
    app: state.app,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
