import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { BsList } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
// import { Scrollbars } from "react-custom-scrollbars";
import ShadowScrollbars from "../../assets/ShadowScrollbars";

import CartList from "../../components/CartList";
import Cart from "../../components/Cart";
import Struk from "../../components/Cart/struk";
import Navbar from "../../components/Navbar";
import Show from "../../components/Show";

import APP_ACTION from "../../stores/actions/app";
import CART_ACTION from "../../stores/actions/cart";
import useConfig from "../../hooks/useConfig";
import useCategoriesWithVariants from "../../hooks/useCategoriesWithVariants";

import "./Transaction.scss";

const Transaction = ({ setConfig, setProductUpc, removeCartDiscount }) => {
	const [itemActive, setItemActive] = useState(0);
	const [categoryActive, setCategoryActive] = useState({});
	const [products, setProducts] = useState([]);
	const mount = useRef(null);

	const { configResponse } = useConfig();
	const {
		categories,
		categoriesLoading,
		requestCategory,
	} = useCategoriesWithVariants();

	useEffect(() => {
		mount.current = true;
		if (configResponse !== null) {
			let data = configResponse.data;
			if (mount.current) setConfig(data);
		}
		return () => (mount.current = false);
	}, [configResponse, setConfig]);

	useEffect(() => {
		if (categories !== null) {
			let itemCategory = categories.data[0];
			if (mount.current) setAllData({ item: itemCategory });
		}
	}, [categories]);

	const chooseCategory = ({ item, index }) => {
		if (mount.current) {
			setProducts([]);
			setItemActive(index);
			setAllData({ item });
		}
	};

	const setAllData = ({ item }) => {
		let dataProducts = item.products.filter((item, index) => {
			return item.catId === item.id;
		});
		if (mount.current) {
			setCategoryActive(item);
			setProducts(dataProducts);
		}
	};

	const renderListCategory = () => {
		if (categoriesLoading) {
			return <div>Loading...</div>;
		} else {
			let maxHeight = window.innerHeight - 105;
			return (
				<ShadowScrollbars autoHide={true} style={{ height: maxHeight }}>
					<ul className="Transaction-Body-Categories">
						{categories.data.map((item, index) => {
							// prettier-ignore
							let classActive = itemActive === index ? "Transaction-Body-Categories-Item Transaction-Body-Item-Active" : "Transaction-Body-Categories-Item";
							return (
								<li
									className={classActive}
									key={index}
									onClick={() => {
										chooseCategory({
											item,
											index,
										});
									}}
								>
									<span className="img-category">
										<BsList size={24} />
									</span>
									{item.catName}
								</li>
							);
						})}
					</ul>
				</ShadowScrollbars>
			);
		}
	};

	const callbackSearch = (data) => {
		const { text, upc } = data;
		let emptyProduct = {
			added: false,
			product: {},
		};
		if (upc) {
			if (text === "") {
				setProductUpc(emptyProduct);
			} else {
				if (categories.data.length > 0) {
					let dataCategories = categories.data;
					if (dataCategories !== undefined) {
						let foundItemCount = 0;
						dataCategories.map((item) => {
							let products = item.products;
							if (products.length > 0) {
								let productsFilter = products.filter((item) => {
									return item.prodUpc === text;
								});
								if (productsFilter.length > 0) {
									setProductUpc({
										product: productsFilter[0],
										added: true,
									});
									foundItemCount++;
								} else {
									setProductUpc(emptyProduct);
								}
							}
							return null;
						});

						if (foundItemCount < 1) toast.error("Produk tidak ditemukan");
						/** --- */
					}
					/** --- */
				}
				/** --- */
			}
		} else {
			let products = categoryActive.products;
			let newDataProducts = products.filter((item) => {
				let prodItemName = item.prodName.toLowerCase();
				let isAllow = prodItemName.startsWith(text.toLowerCase());
				return isAllow;
			});
			setProductUpc(emptyProduct);
			setProducts(newDataProducts);
		}
	};

	const callbackCart = (data) => {
		if (data.trigger !== undefined) {
			if (data.trigger) {
				requestCategory();
				setItemActive(0);
				removeCartDiscount(0);
			}
		}
	};

	return (
		<div className="Transaction-Frame">
			<div className="Transaction">
				<Navbar />
				<div className="Transaction-Body">
					<Container fluid={true}>
						<Row>
							<Col xs={12} md={8}>
								<Row className="Transaction-Body-Left">
									<Col
										xs={12}
										md={2}
										className="Transaction-Body-Left-Categories"
									>
										{categories !== null && renderListCategory()}
									</Col>
									<Col
										xs={12}
										md={10}
										className="Transaction-Body-Left-Products"
									>
										<Show when={categories !== null}>
											<CartList
												category={categoryActive}
												loading={categoriesLoading}
												data={products}
												callbackSearch={callbackSearch}
											/>
										</Show>
									</Col>
								</Row>
							</Col>
							<Col xs={12} md={4}>
								<Cart callbackCart={callbackCart} />
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<Struk />
			<ToastContainer />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setConfig: (data) => dispatch(APP_ACTION.setConfig(data)),
	setProductUpc: (data) => dispatch(APP_ACTION.setProductUpc(data)),
	removeCartDiscount: () => dispatch(CART_ACTION.setDiscount(0)),
});

const mapStateToProps = (state) => ({
	app: state.app,
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
