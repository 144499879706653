import Login from "../pages/Login";
import Transaction from "../pages/Transaction";
import Profile from "../pages/Profile";

export const routes = [
	{
		path: "/",
		component: Login,
		exact: true,
	},
	{
		path: "/transaction",
		component: Transaction,
		exact: true,
		auth: true,
	},
	{
		path: "/profile",
		component: Profile,
		exact: true,
	},
];
