import React, { useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import { BiTimeFive, BiPrinter } from "react-icons/bi";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { FaRegFile, FaRegCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import Show from "../Show";
import StateEmpty from "../StateEmpty";
import Loader from "../Loader";
import Api from "../../services";
import * as GeneralHelper from "../../helpers/General";

import ORDER_ACTION from "../../stores/actions/order";
import CART_ACTION from "../../stores/actions/cart";

import * as General from "../../helpers/General";

import "./style/History.scss";

let delayTimer;

function History({
    user,
    orderHistory,
    setHistory,
    setCartItemsPrint,
    setCartDiscount,
}) {
    const [showHistoryOrder, setShowHistory] = useState(false);
    const [historySearch, setHistorySearch] = useState("");
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [detailOrder, setDetailOrder] = useState(null);
    const [itemActive, setItemActive] = useState(-1);
    const [loadingPrint, setLoadingPrint] = useState(false);

    const closeHistory = () => setShowHistory(false);
    const historyShow = () => {
        setShowHistory(true);
        getHistory();
    };

    const getHistory = async (search = false) => {
        let dataPost = { empId: user.empid };
        if (search && search !== "") dataPost.s = search;

        let res = await Api.service({
            method: "POST",
            url: Api.routes.HISTORY_LIST,
            data: dataPost,
        });

        let data = res.data.data;
        setDetailOrder(null);
        setHistory(data);
        setLoadingSearch(false);
        setItemActive(-1); // reset
    };

    const _onChange = (input) => {
        setLoadingSearch(true);
        let text = input.target.value;
        setHistory([]);
        setHistorySearch(text);
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => getHistory(text), 1000);
    };

    const RenderContent = () => {
        let maxHeightPayment = window.innerHeight - 230;
        return (
            <>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Cari riwayat transaksi"
                    style={{ marginBottom: 10 }}
                    value={historySearch}
                    onChange={(text) => _onChange(text)}
                />
                <Show when={loadingSearch}>
                    <Loader />
                </Show>
                <Show when={!loadingSearch && orderHistory?.length < 1}>
                    <StateEmpty label="Tidak ada data history" />
                </Show>
                <Scrollbars style={{ height: maxHeightPayment }}>
                    <ul>
                        {orderHistory &&
                            orderHistory.map((item, index) => {
                                const orderDay = item.orderDay;
                                const orderMonth = item.orderMonth;
                                const orderYear = item.orderYear;
                                const orderTime = item.orderTime;
                                const orderTotal = item.orderTotal;
                                const orderTax = item.orderTax;
                                const isHasTax = parseFloat(orderTax) > 1;
                                const orderTaxAmount = item.orderTaxAmount;

                                // prettier-ignore
                                const orderDateTime = orderDay + "-" + orderMonth + "-" + orderYear + " " + orderTime;
                                // prettier-ignore
                                const labelTax = isHasTax ? " inc Pajak : " + General.numberFormat(null, orderTaxAmount) : ""
                                // prettier-ignore
                                const classActive = itemActive === index ? "listHistory active" : "listHistory";
                                return (
                                    <li
                                        key={"history-" + index}
                                        className={classActive}
                                        onClick={() => {
                                            setDetailOrder(item);
                                            setItemActive(index);
                                        }}
                                    >
                                        <div className="headHistoryList">
                                            <span className="orderList">
                                                <FaRegFile />{" "}
                                                {item.orderInvoice +
                                                    " / " +
                                                    item.orderCode}
                                            </span>
                                            <span className="orderDate">
                                                <FaRegCalendarAlt />{" "}
                                                {orderDateTime}
                                            </span>
                                        </div>
                                        <div className="bodyHistory">
                                            <div className="priceHistory">
                                                {labelTax}
                                                <span>
                                                    {General.numberFormat(
                                                        null,
                                                        orderTotal
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </Scrollbars>
            </>
        );
    };

    const onReprintInvoice = () => {
        setLoadingPrint(true);
        try {
            const details = detailOrder.details;
            console.log(details);
            const orderGrandTotal = detailOrder.orderTotal;
            const orderDiscountCart = parseInt(detailOrder.orderDiscounts);
            setCartDiscount(orderDiscountCart);
            let cartItems = {};

            let orderProductDiscount = 0;
            for (let detail_index in details) {
                const detail_item = details[detail_index];
                const prodId = detail_item.prodId;
                const prodName = detail_item.odetProdName;
                const prodSku = detail_item.odetProdSku;
                const prodPrice = detail_item.odetPricePerunit;
                const prodDiscount = detail_item.odetProdDiscount;
                const prodUnitQty = detail_item.odetUnitQty;
                const prodQty = detail_item.odetQty;

                orderProductDiscount =
                    orderProductDiscount + parseInt(prodDiscount);

                cartItems = {
                    ...cartItems,
                    [prodId]: {
                        name: prodName,
                        sku: prodSku,
                        price: prodPrice,
                        price_type: "finalprice",
                        discount: prodDiscount,
                        unitqty: prodUnitQty,
                        qty: prodQty,
                        prices: {
                            normalprice: prodPrice,
                            wholesaleprice: 0,
                            specialprice: 0,
                        },
                    },
                };
            }

            const orderDiscountTotal = orderDiscountCart + orderProductDiscount;
            const orderGrandTotalFinal = orderGrandTotal - orderDiscountTotal;
            let paymentAmount = undefined;
            let paymentReturn = undefined;
            if (detailOrder.orderPaymentMeta !== null) {
                const obj_meta = JSON.parse(detailOrder.orderPaymentMeta);
                paymentAmount = obj_meta.paymentAmount;
                paymentReturn = obj_meta.paymentReturn;
            }

            const obj_print = {
                paymentAmount,
                paymentReturn,
                grandTotal: orderGrandTotalFinal,
                subTotal: detailOrder.orderSubtotal,
                dataInvoice: detailOrder,
                cartItems,
            };

            obj_print.paymentAmount = obj_print.paymentAmount
                .toString()
                .replace("IDR ", "")
                .replace(/,/g, "");
            obj_print.paymentReturn = obj_print.paymentReturn
                .toString()
                .replace("IDR ", "")
                .replace(/,/g, "");
            setCartItemsPrint(obj_print);
            setTimeout(() => {
                setLoadingPrint(false);
                window.print();
            }, 1000);
        } catch (err) {
            console.log("[err] while print", err);
            toast.error("your data transaction can't printed again");
        }
    };

    const RenderDetailOrder = () => {
        if (detailOrder === null)
            return (
                <Col xs={12} md={6} className="DetailTransaction">
                    <StateEmpty label="Tidak ada detail order yang dipilih" />
                </Col>
            );

        const currency = "IDR ";
        const orderTaxAmount = parseInt(detailOrder.orderTaxAmount);
        const orderDiscount = parseInt(detailOrder.orderDiscounts);
        const subtotal = parseInt(detailOrder.orderTotal);
        const orderDetails = detailOrder.details;
        let prodTotalDiscount = 0;
        for (let orderDetailIndex in orderDetails) {
            const orderDetailItem = orderDetails[orderDetailIndex];
            const prodDiscount = parseInt(orderDetailItem.odetProdDiscount);
            prodTotalDiscount = prodTotalDiscount + prodDiscount;
        }

        const discountFinal = orderDiscount + prodTotalDiscount;
        const grandtotal = subtotal - discountFinal;
        const maxHeightPayment = window.innerHeight - 400;
        return (
            <Col xs={12} md={6} className="DetailTransaction">
                <h5>Detail Transaksi</h5>
                <Scrollbars style={{ height: maxHeightPayment }}>
                    <ul className="productDetail">
                        {orderDetails.map((item, index) => {
                            let prodName =
                                item.odetProdName +
                                " (" +
                                item.odetProdSku +
                                ")";
                            let prodQty = parseInt(item.odetQty);
                            let price = item.odetPricePerunit;
                            let prodPrice = GeneralHelper.numberFormat(
                                currency,
                                price,
                                "."
                            );
                            let prodPriceMix = prodQty * price;
                            // prettier-ignore
                            let prodPriceFinal = GeneralHelper.numberFormat(currency, prodPriceMix, '.');
                            let prodDiscount = parseInt(item.odetProdDiscount);
                            let isDiscount = prodDiscount > 0;
                            // prettier-ignore
                            let prodDiscountLabel = GeneralHelper.numberFormat(currency, prodDiscount, '.');
                            return (
                                <li key={"order-detail-item-" + index}>
                                    <span className="ProductNameSku">
                                        {prodName}
                                    </span>
                                    <span className="Qty">
                                        {prodQty} x {prodPrice}{" "}
                                        <span>{prodPriceFinal}</span>
                                    </span>
                                    <Show when={isDiscount}>
                                        <span className="Disc">
                                            Diskon Produk. -{prodDiscountLabel}
                                        </span>
                                    </Show>
                                </li>
                            );
                        })}
                        <Show when={orderTaxAmount > 0}>
                            <li key={"order-detail-item-" + orderTaxAmount}>
                                <span className="ProductNameSku">Tax</span>
                                <span className="Qty">
                                    {GeneralHelper.numberFormat(
                                        currency,
                                        orderTaxAmount,
                                        "."
                                    )}
                                    <span>
                                        {GeneralHelper.numberFormat(
                                            currency,
                                            orderTaxAmount,
                                            "."
                                        )}
                                    </span>
                                </span>
                            </li>
                        </Show>
                    </ul>
                </Scrollbars>
                <hr />
                <div className="CalcTotal">
                    <div className="Subtotal">
                        Subtotal:{" "}
                        <span>
                            {GeneralHelper.numberFormat(
                                currency,
                                subtotal,
                                "."
                            )}
                        </span>
                    </div>
                    <Show when={orderDiscount > 0}>
                        <div className="Discount">
                            Diskon Keranjang:{" "}
                            <span>
                                -
                                {GeneralHelper.numberFormat(
                                    currency,
                                    orderDiscount,
                                    "."
                                )}
                            </span>
                        </div>
                    </Show>
                    <hr />
                    <Show when={discountFinal > 0}>
                        <div className="Discount">
                            Total Diskon:{" "}
                            <span>
                                -
                                {GeneralHelper.numberFormat(
                                    currency,
                                    discountFinal,
                                    "."
                                )}
                            </span>
                        </div>
                    </Show>

                    <div className="Grandtotal">
                        Grandtotal:{" "}
                        <span>
                            {GeneralHelper.numberFormat(
                                currency,
                                grandtotal,
                                "."
                            )}
                        </span>
                    </div>

                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => onReprintInvoice()}
                        disabled={loadingPrint}
                    >
                        {loadingPrint ? "Loading..." : <BiPrinter />}
                    </Button>
                </div>
            </Col>
        );
    };

    const RenderHistory = () => {
        return (
            <>
                <Modal
                    show={showHistoryOrder}
                    onHide={closeHistory}
                    className="HistoryModal"
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Transaksi Hari Ini</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} md={6}>
                                {RenderContent()}
                            </Col>
                            <RenderDetailOrder />
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

    return (
        <>
            <Col lg="7" md="7"></Col>
            <Col
                lg="2"
                md="2"
                className="OrderOption-Item History"
                onClick={() => historyShow()}
            >
                <span>
                    <BiTimeFive size={28} />
                </span>
                History
            </Col>
            {RenderHistory()}
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setHistory: (data) => dispatch(ORDER_ACTION.setHistory(data)),
    setCartItemsPrint: (data) => dispatch(CART_ACTION.setItemsPrint(data)),
    setCartDiscount: (data) => dispatch(CART_ACTION.setDiscount(data)),
});

const mapStateToProps = (state) => ({
    user: state.user.user,
    orderHistory: state.order.history,
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
