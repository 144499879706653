import axios from "axios";
import Storage from "../helpers/Storage";

const API_URL = process.env.REACT_APP_API_URL;

export default {
    routes: {
        SIGNIN: API_URL + "/user/signin",
        CONFIG: API_URL + "/config",
        CONFIG_ADDON: API_URL + "/config/addon",
        PRODUCT_LIST: API_URL + "/product/list",
        STORE_LIST: "/store",
        ORDER_STORE: API_URL + "/order/store",
        ORDER_CLOSING: API_URL + "/order/closing",
        CATEGORY_PRODUCT_VARIANTS: API_URL + "/product/categories",
        HISTORY_LIST: API_URL + "/order/history",
        MEMBER_LIST: API_URL + "/user/member",
    },
    service: ({ method = "GET", url, data = {} }) => {
        let token = Storage.get(Storage.name.TOKEN);
        let config = {
            baseURL: API_URL,
            method: method,
            url: url,
        };

        if (method === "POST") config.data = data;
        if (token !== null) config.headers = { Authorization: token };
        return axios(config);
    },
};
