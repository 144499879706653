import { useState, useEffect, useRef } from "react";
import Api from "../services";

function useCategoriesWithVariants() {
	const [categoriesLoading, setLoading] = useState(false);
	const [categories, setCategories] = useState(null);
	const [categoriesError, setCategoriesError] = useState(null);

	let mount = useRef();

	useEffect(() => {
		mount.current = true;
		if (mount.current) requestCategory();
		return () => (mount.current = false);
	}, []);

	const requestCategory = () => {
		let config = {
			method: "GET",
			url: Api.routes.CATEGORY_PRODUCT_VARIANTS,
		};

		if (mount.current) setLoading(true);
		Api.service(config)
			.then((res) => {
				let data = res.data;
				if (mount.current) {
					setCategories(data);
					setLoading(false);
				}
			})
			.catch((err) => {
				let errResponse = err.response;
				if (errResponse !== undefined) {
					let data = errResponse.data;
					if (mount.current) setCategoriesError(data);
				}
				if (mount.current) setLoading(false);
			});
	};

	return { categoriesLoading, categories, categoriesError, requestCategory };
}

export default useCategoriesWithVariants;
