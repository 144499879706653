import CART_ACTION from "../actions/cart";

const initialState = {
    items: {},
    items_print: {},
    payment_method: { default: "", choose: "" },
    discount: 0,
    member: null,
};

const REDUCER_CART = (prevState = initialState, action) => {
    switch (action.type) {
        case CART_ACTION.name.CART_ITEMS:
            return {
                ...prevState,
                items: action.items,
            };
        case CART_ACTION.name.CART_ITEMS_PRINT:
            return {
                ...prevState,
                items_print: action.items_print,
            };
        case CART_ACTION.name.CART_DISCOUNT:
            return {
                ...prevState,
                discount: action.discount,
            };
        case CART_ACTION.name.CART_PAYMENT_METHOD:
            return {
                ...prevState,
                payment_method: action.payment_method,
            };
        case CART_ACTION.name.CART_MEMBER:
            return {
                ...prevState,
                member: action.member,
            };
        default:
            return prevState;
    }
};

export default REDUCER_CART;
