import ORDER_ACTION from "../actions/order";

const initialState = {
	efund: 0,
	history: [],
};

const REDUCER_ORDER = (prevState = initialState, action) => {
	switch (action.type) {
		case ORDER_ACTION.name.ORDER_EFUND:
			return {
				...prevState,
				efund: action.efund,
			};
		case ORDER_ACTION.name.ORDER_HISTORY:
			return {
				...prevState,
				history: action.history,
			};
		default:
			return prevState;
	}
};

export default REDUCER_ORDER;
