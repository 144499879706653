import React from "react";
import { BsXCircle } from "react-icons/bs";

import Show from "../Show";

import * as Colors from "../../assets/styles/colors";
import "./StateEmpty.scss";

function StateEmpty({ icon, iconSize = 70, label }) {
	return (
		<div className="StateEmpty mt-3 mb-3">
			<div>
				<Show when={!icon}>
					<BsXCircle size={iconSize} color={Colors.GRAY_SMOOTH_2} />
				</Show>
				<Show when={icon}>{icon}</Show>
			</div>
			<div className="mt-2 StateEmpty-NoDataLabel">{label}</div>
		</div>
	);
}

export default StateEmpty;
