const ORDER_EFUND = "[ORDER].EFUND";
const ORDER_HISTORY = "[ORDER].HISTORY";

export default {
	name: {
		ORDER_EFUND: ORDER_EFUND,
		ORDER_HISTORY: ORDER_HISTORY,
	},
	setEfund: (data) => {
		return {
			type: ORDER_EFUND,
			efund: data,
		};
	},
	setHistory: (data) => {
		return {
			type: ORDER_HISTORY,
			history: data,
		};
	},
};
