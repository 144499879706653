import React from "react";

import { FaSpinner } from "react-icons/fa";

function Loader() {
	return (
		<div className="Loader text-center mt-3 mb-3">
			<FaSpinner size={32} className="spinner Loader-Spinner mb-3" />
			<div>Data lagi diproses, mohon tunggu sebentar</div>
		</div>
	);
}

export default Loader;
