import React, { useState } from "react";
import {
    Container,
    Button,
    Row,
    Col,
    Modal,
    Form,
    InputGroup,
} from "react-bootstrap";
import CART_ACTION from "../../stores/actions/cart";
import { connect } from "react-redux";
import { FaWindowClose, FaSadCry } from "react-icons/fa";
import { BiPrinter } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";

import Api from "../../services";
import * as General from "../../helpers/General";
import CashInput from "./cash-input";

function PlaceOrderModal({
    app,
    cart,
    user,
    grandTotal,
    discount,
    clearCartItems,
    setCartItemsPrint,
    setPaymentMethod,
    setMember,
    callbackCart,
}) {
    const [valuePayment, setValuePayment] = useState(0);
    const [showConfirmOrder, setConfirmOrder] = useState(false);
    const [modalConfirmCancel, setShowConfirmCancel] = useState(false);

    const confirmClose = () => setConfirmOrder(false);
    const confirmShow = () => {
        if (grandTotal < 1)
            toast.error("Nilai nominal minus harap diperhatikan");
        else setConfirmOrder(true);
    };
    const closeConfirmCancel = () => setShowConfirmCancel(false);
    const showConfirmCancel = () => setShowConfirmCancel(true);

    const { items, payment_method, member } = cart;
    const { config } = app;

    const clickPayment = () => {
        const returnPayment = valuePayment - grandTotal;
        if (returnPayment < 0) {
            toast.error("Nilai nominal salah atau pembayaran belum selesai");
        } else {
            placeOrderAndPayment();
        }
    };

    const clickCancel = () => {
        clearCartItems();
    };

    const placeOrderAndPayment = async () => {
        const discountFinal = discount
            .toString()
            .replace("IDR ", "")
            .replace(/,/g, "");
        const valuePaymentFilter = parseInt(
            valuePayment.toString().replace("IDR ", "").replace(/,/g, "")
        );
        const paymentReturn = valuePaymentFilter - grandTotal;
        const paymenmeta = JSON.stringify({
            paymentAmount: valuePaymentFilter || 0,
            paymentReturn: paymentReturn || 0,
        });
        const dataPost = {
            empId: user.empid,
            messageorder: "",
            currency: config.defaultcurrency,
            lang: config.language,
            paymenmethod: payment_method.choose,
            paymenmeta,
            store: user.store.storeId,
            cart: items,
            discount: discountFinal,
        };

        if (member !== null) dataPost.memId = member?.mId;

        const res = await Api.service({
            method: "POST",
            url: Api.routes.ORDER_STORE,
            data: dataPost,
        });

        const data = res.data;
        const status = data.status;
        if (status) {
            const dataInvoice = data.data;
            const items_print = {
                ...cart.items_print,
                subTotal: parseInt(dataInvoice.orderSubtotal),
                member,
                dataInvoice,
            };
            toast.success("Pembayaran berhasil di proses");
            setConfirmOrder(false);
            setCartItemsPrint(items_print);
            window.print();
            clearCartItems();
            setMember(null);
            callbackCart({ trigger: true, discount });
            setPaymentMethod({
                ...payment_method,
                choose: payment_method.default,
            });
        }
    };

    const renderCancelOrder = () => {
        return (
            <>
                <Modal
                    show={modalConfirmCancel}
                    onHide={closeConfirmCancel}
                    backdropClassName="CancelOrderBackdrop"
                    className="MembersModal CancelOrder"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="MemberContainer">
                            <Row className="MemberItems">
                                <Col
                                    xs={12}
                                    md={12}
                                    className="PlaceOrderContainer"
                                >
                                    Jika ingin membatalkan pesanan, anda harus
                                    memasukkan ulang kembali pesanan.
                                    <br />
                                    Apa kamu yakin ingin membatalkan pesanan
                                    ini?
                                    <div className="iconCancel">
                                        <FaSadCry />
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    md={12}
                                    className="PlaceOrderContainer Button"
                                >
                                    <Button
                                        variant="danger"
                                        onClick={clickCancel}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={closeConfirmCancel}
                                    >
                                        No
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

    const onChangeReturn = (text) => {
        const value = text.target.value;
        const valueFinal = value
            .toString()
            .replace("IDR ", "")
            .replace(/,/g, "");

        const total = parseInt(valueFinal);
        const returnPayment = total - grandTotal;
        const items_print = {
            paymentAmount: total,
            paymentReturn: returnPayment,
            cartItems: items,
            grandTotal,
        };
        setValuePayment(value);
        setCartItemsPrint(items_print);
    };

    const callbackCashInput = (data) => {
        let total = 0;
        if (data.type === "all") total = data.value;
        else total = valuePayment + data.value;

        const returnPayment = total - grandTotal;
        setValuePayment(total);
        setCartItemsPrint({
            paymentAmount: total,
            paymentReturn: returnPayment,
            cartItems: items,
            grandTotal,
        });
    };

    const renderPaymentElm = () => {
        const valueFinal = valuePayment
            .toString()
            .replace("IDR ", "")
            .replace(/,/g, "");
        const returnPayment = parseInt(valueFinal) - grandTotal;
        const returnPaymentFinal =
            returnPayment < 0
                ? "Pembayaran Belum Selesai"
                : General.numberFormat(null, returnPayment);
        return (
            <>
                <Row>
                    <Col xs={12} md={6}>
                        <h4>
                            Grand Total :{" "}
                            {General.numberFormat(null, grandTotal)}
                        </h4>
                        <h5>
                            Payment Method :{" "}
                            {General.ucwords(payment_method.choose)}
                        </h5>
                        <Form.Group controlId="UangTerima">
                            <Form.Label>Di Bayar</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="text"
                                    className="kembalian-input"
                                    placeholder="Uang Terima"
                                    size="lg"
                                    value={General.numberFormat(
                                        null,
                                        valuePayment
                                    )}
                                    onChange={onChangeReturn}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="danger"
                                        onClick={() => setValuePayment(0)}
                                    >
                                        Clear
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <CashInput
                            callbackCashInput={callbackCashInput}
                            grandTotal={grandTotal}
                        />
                    </Col>
                </Row>
                <hr />
                <div className="kembalian">
                    Total Kembalian: <span>{returnPaymentFinal}</span>
                </div>
                <div className="payButton">
                    <Button
                        className="PlaceOrderBtn"
                        variant="success"
                        onClick={() => clickPayment()}
                    >
                        <BiPrinter />
                        Cetak Struk
                    </Button>
                    <Button
                        className="PlaceOrderBtn Cancel"
                        variant="danger"
                        onClick={() => showConfirmCancel()}
                    >
                        <FaWindowClose />
                        Cancel Order
                    </Button>
                </div>
            </>
        );
    };

    const renderConfirmModal = () => {
        return (
            <Modal
                show={showConfirmOrder}
                onHide={confirmClose}
                size="lg"
                backdropClassName="PlaceOrderBackdrop"
                className="MembersModal PlaceOrder"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Place Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="MemberContainer">
                        <Row className="MemberItems">
                            <Col
                                xs={12}
                                md={12}
                                className="PlaceOrderContainer"
                            >
                                {renderPaymentElm()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <Button variant="success" onClick={() => confirmShow()}>
                Place Order{" "}
                <span className="nominal">
                    {General.numberFormat(null, grandTotal)}
                </span>
            </Button>
            {renderConfirmModal()}
            {renderCancelOrder()}
            <ToastContainer />
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    clearCartItems: () => dispatch(CART_ACTION.setItems({})),
    setCartItemsPrint: (data) => dispatch(CART_ACTION.setItemsPrint(data)),
    setCartDiscount: (data) => dispatch(CART_ACTION.setDiscount(data)),
    setPaymentMethod: (data) => dispatch(CART_ACTION.setPaymentMethod(data)),
    setMember: (data) => dispatch(CART_ACTION.setMember(data)),
});

const mapStateToProps = (state) => ({
    cart: state.cart,
    app: state.app,
    user: state.user.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderModal);
