/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Modal, Image } from "react-bootstrap";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { Scrollbars } from "react-custom-scrollbars";

import CART_ACTION from "../../stores/actions/cart";
import Show from "../Show";

import { ucwords } from "../../helpers/General";

const getImagePayment = (slug) => {
    let imageName = "";
    switch (slug) {
        case "bca":
            imageName = "./payment/bca.svg";
            break;
        case "mandiri":
            imageName = "./payment/mandiri.svg";
            break;
        case "bni":
            imageName = "./payment/bni.svg";
            break;
        case "bri":
            imageName = "./payment/bri.svg";
            break;
        case "ovo":
            imageName = "./payment/ovo.svg";
            break;
        case "gopay":
            imageName = "./payment/gopay.svg";
            break;
        case "jenius":
            imageName = "./payment/jenius.svg";
            break;
        case "dana":
            imageName = "./payment/dana.svg";
            break;
        case "linkaja":
            imageName = "./payment/linkAja.svg";
            break;
        case "shopee":
            imageName = "./payment/shopee.svg";
            break;
        default:
            imageName = "";
            break;
    }

    return imageName;
};

const PaymentModal = ({ app, cart, paymentMethod, setPaymentMethod }) => {
    const [showPaymentMethod, setShowPayment] = useState(false);
    const paymentMethodClose = () => setShowPayment(false);
    const paymentMethodShow = () => setShowPayment(true);
    const modalElm = useRef(null);
    const mount = useRef();

    const { payment_method } = cart;
    const { config } = app;

    useEffect(() => {
        mount.current = true;
        if (mount.current && config !== null) {
            const isMultiPayment = config?.multipayment === "yes";
            if (!isMultiPayment) {
                const paymentMethodConfig = config?.paymentmethod;
                const paymentMethodExplode = paymentMethodConfig?.split(",");
                const paymentMethodChoose = paymentMethodExplode[0]?.split(":");
                setPaymentMethod({
                    ...payment_method,
                    choose: paymentMethodChoose[0],
                });
            }
        }

        return () => (mount.current = false);
    }, [config]);

    const clickItemPaymentMethod = (item) => {
        console.log({ ...payment_method, choose: item });
        setPaymentMethod({ ...payment_method, choose: item });
        paymentMethodClose();
    };

    const renderModalPayment = () => {
        let maxHeightPayment = window.innerHeight - 250;
        return (
            <Modal
                ref={modalElm}
                show={showPaymentMethod}
                onHide={paymentMethodClose}
                className="PaymentModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Scrollbars
                        autoHide={true}
                        style={{ height: maxHeightPayment }}
                    >
                        <Container className="PaymentContainer">
                            <Row className="PaymentItems">
                                <Show when={paymentMethod}>
                                    {paymentMethod.map((item, index) => {
                                        return (
                                            <Col
                                                key={"payment-method-" + index}
                                                className={
                                                    "PaymentItem " + item
                                                }
                                                onClick={() =>
                                                    clickItemPaymentMethod(item)
                                                }
                                                xs={12}
                                                md={12}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={1}
                                                        md={1}
                                                        className="RadioPayment"
                                                    >
                                                        <Form.Check.Input
                                                            type="radio"
                                                            name="payment"
                                                            id={
                                                                `radio-cash-` +
                                                                item
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={11}
                                                        md={11}
                                                        className="RadioPaymentLabel"
                                                    >
                                                        <label
                                                            htmlFor={
                                                                "radio-cash-" +
                                                                item
                                                            }
                                                        >
                                                            <Row>
                                                                <Col
                                                                    xs={7}
                                                                    md={9}
                                                                    className="LogoName"
                                                                >
                                                                    {ucwords(
                                                                        item
                                                                    )}
                                                                </Col>
                                                                <Show
                                                                    when={
                                                                        item ===
                                                                            "cash" ||
                                                                        item ===
                                                                            "debit"
                                                                    }
                                                                >
                                                                    <Col
                                                                        xs={5}
                                                                        md={3}
                                                                        className="Logo"
                                                                    >
                                                                        <FaRegMoneyBillAlt />
                                                                    </Col>
                                                                </Show>
                                                                <Show
                                                                    when={
                                                                        item !==
                                                                            "cash" ||
                                                                        item !==
                                                                            "debit"
                                                                    }
                                                                >
                                                                    <Image
                                                                        src={getImagePayment(
                                                                            item
                                                                        )}
                                                                        fluid
                                                                    />
                                                                </Show>
                                                            </Row>
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        );
                                    })}
                                </Show>
                            </Row>
                        </Container>
                    </Scrollbars>
                </Modal.Body>
            </Modal>
        );
    };

    const imageLogoURL = getImagePayment(payment_method.choose);
    const isImageEmpty = imageLogoURL === "";
    const isNotBrand =
        payment_method.choose === "cash" || payment_method.choose === "debit";
    return (
        <>
            <div
                className="BtnPayment"
                onClick={() => {
                    const isMultiPayment = config?.multipayment === "yes";
                    if (isMultiPayment) paymentMethodShow();
                }}
            >
                <div className="payDesc">
                    <Show when={isNotBrand}>
                        <span>
                            <FaRegMoneyBillAlt />
                        </span>
                        {ucwords(payment_method.choose)}
                    </Show>
                    <Show when={!isNotBrand}>
                        <Show when={isImageEmpty}>
                            {ucwords(payment_method.choose)}
                        </Show>
                        <Show when={!isImageEmpty}>
                            <Image src={imageLogoURL} width={25} />
                        </Show>
                    </Show>
                </div>
            </div>
            {renderModalPayment()}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPaymentMethod: (data) => dispatch(CART_ACTION.setPaymentMethod(data)),
});

const mapStateToProps = (state) => ({
    app: state.app,
    cart: state.cart,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
