import React, { useState } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

function DeleteConfirmModal({ callbackDelete, item }) {
	const [showConfirmDel, setShowConfirmDel] = useState(false);
	const delConfirmClose = () => setShowConfirmDel(false);
	const delConfirmShow = () => setShowConfirmDel(true);
	const delConfirm = () => {
		callbackDelete(item);
		setShowConfirmDel(false);
	};

	const renderConfirmModal = () => {
		return (
			<>
				<Modal
					show={showConfirmDel}
					onHide={delConfirmClose}
					className="MembersModal RemoveProduct"
				>
					<Modal.Header closeButton>
						<Modal.Title>Remove Product</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container className="MemberContainer">
							<Row className="MemberItems">
								<Col xs={12} md={12}>
									Apa kamu yakin ingin hapus produk ini dari keranjang belanja?
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => delConfirmClose()}>
							Batal
						</Button>
						<Button variant="danger" onClick={() => delConfirm()}>
							Hapus
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	};

	return (
		<>
			<div className="Cart-Item-Remove" onClick={delConfirmShow}>
				<Button variant="danger">
					<FaTrash />
				</Button>
			</div>
			{renderConfirmModal()}
		</>
	);
}

export default DeleteConfirmModal;
