import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FaCoins } from "react-icons/fa";
import "./style/CashInput.scss";

function CashInput({ callbackCashInput, grandTotal }) {
	const clickCashInput = (value, type = "") => {
		callbackCashInput({
			type: type,
			value: value,
		});
	};

	return (
		<>
			<div className="titleMoney">Small Pieces</div>
			<Row className="coin">
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(100)}
					>
						<FaCoins /> 100
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(200)}
					>
						<FaCoins /> 200
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(500)}
					>
						<FaCoins /> 500
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(1000)}
					>
						<FaCoins /> 1.000
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(2000)}
					>
						2.000
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(5000)}
					>
						5.000
					</Button>
				</Col>
			</Row>
			<div className="titleMoney">Big Pieces</div>
			<Row className="coin">
				<Col xs={3} md={8} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(grandTotal, "all")}
					>
						Uang Pas
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(10000)}
					>
						10.000
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(20000)}
					>
						20.000
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(50000)}
					>
						50.000
					</Button>
				</Col>
				<Col xs={3} md={4} className="CashInput">
					<Button
						variant="success"
						size="lg"
						onClick={() => clickCashInput(100000)}
					>
						100.000
					</Button>
				</Col>
			</Row>
		</>
	);
}

export default CashInput;
