import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as General from "../../helpers/General";

import Show from "../Show";
import "./style/Struk.scss";

const Struk = ({ cart, config, user }) => {
    const [dataItems, setDataItems] = useState([]);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const { items_print, discount } = cart;
    const mount = useRef();
    const member = items_print?.member;

    useEffect(() => {
        mount.current = true;
        if (mount.current) {
            const onDataCartItems = () => {
                let dataItems = [];
                let discountProduct = 0;
                for (let index in items_print.cartItems) {
                    const cartItem = items_print.cartItems[index];
                    const cartItemTotal =
                        cartItem.qty * parseFloat(cartItem.price);
                    const discountItem = parseFloat(
                        cartItem.discount
                            .toString()
                            .replace("IDR ", "")
                            .replace(/,/g, "")
                    );
                    discountProduct = discountProduct + discountItem;
                    dataItems.push({
                        name: cartItem.name,
                        sku: cartItem.sku,
                        qty: cartItem.qty,
                        price: cartItem.price,
                        total: cartItemTotal,
                        discount: cartItem.discount,
                    });
                }
                const getTotalDiscount =
                    parseInt(discount) + parseInt(discountProduct);
                setDataItems(dataItems);
                setTotalDiscount(getTotalDiscount);
            };
            onDataCartItems();
        }
        return () => (mount.current = false);
    }, [items_print, discount]);

    const renderCartItems = () => {
        if (dataItems.length > 0) {
            return (
                <>
                    {dataItems.map((item, index) => {
                        return (
                            <div className="Items" key={"cart-items-" + index}>
                                <div className="Items-top">
                                    <div className="name">{item.name}</div>
                                </div>
                                <div className="ItemsBottom">
                                    <div className="qty">
                                        {parseInt(item.qty)}
                                    </div>
                                    <div className="much">x</div>
                                    <div className="subtotal">
                                        {General.formatCurrency({
                                            value: item.price,
                                        })}
                                    </div>
                                    <div className="total">
                                        {General.formatCurrency({
                                            value: item.total,
                                        })}
                                    </div>
                                </div>
                                <Show when={item.discount > 0}>
                                    <div className="Items-discount">
                                        Diskon Item:{" "}
                                        <span>
                                            -{" "}
                                            {General.formatCurrency({
                                                value: item.discount,
                                            })}
                                        </span>
                                    </div>
                                </Show>
                            </div>
                        );
                    })}
                </>
            );
        }
    };

    const dateTimeOrder = () => {
        const day = items_print.dataInvoice.orderDay;
        const month = items_print.dataInvoice.orderMonth;
        const year = items_print.dataInvoice.orderYear;
        const date = day + "/" + month + "/" + year;
        const time = items_print.dataInvoice.orderTime;

        return {
            date,
            time,
        };
    };

    const isNullSubtotal = items_print.grandTotal === undefined;
    const isNullPaymentAmount = items_print.paymentAmount === undefined;
    const isNullPaymentReturn = items_print.paymentReturn === undefined;
    const amount_tax = parseInt(items_print?.dataInvoice?.orderTaxAmount);
    return (
        <div className="strukContainer">
            
            <div className="strukHeader">
                <div className="strukLogoTitle">{user.store.storeName}</div>
                <div
                    className="strukHeadDesc"
                    dangerouslySetInnerHTML={{
                        __html: config !== null ? `${config.siteaddress}` : "",
                    }}
                />
            </div>
            <div className="strukBody">
                <div className="cashier">
                    Kasir <span>{user.username}</span>
                </div>
                <Show when={member !== null && member !== undefined}>
                    <div className="cashier">
                        Member <span>{member?.mName}</span>
                    </div>
                </Show>
                {items_print.dataInvoice !== undefined && (
                    <div className="BodyTop">
                        <div className="strukNo">
                            {items_print.dataInvoice.orderCode}
                        </div>
                        <div className="date">{dateTimeOrder().date}</div>
                        <div className="time">{dateTimeOrder().time}</div>
                    </div>
                )}
                <div className="BodyItems">{renderCartItems()}</div>
                <div className="bodyBottom">
                    <div className="subtotal">
                        Sub Total:{" "}
                        <span>
                            {General.formatCurrency({
                                value: items_print.subTotal,
                            })}
                        </span>
                    </div>
                    <Show when={amount_tax > 0}>
                        <div className="tax">
                            Pajak:{" "}
                            <span>
                                {General.formatCurrency({ value: amount_tax })}
                            </span>
                        </div>
                    </Show>
                    <Show when={discount > 0}>
                        <div className="disc">
                            Diskon Keranjang:{" "}
                            <span>{General.numberFormat(null, discount)}</span>
                        </div>
                    </Show>
                    <Show when={totalDiscount > 0 || totalDiscount !== null}>
                        <div className="disc">
                            Total Diskon:{" "}
                            <span>
                                {General.formatCurrency({
                                    value: totalDiscount,
                                })}
                            </span>
                        </div>
                    </Show>
                    <div className="grandtotal">
                        Grand Total:{" "}
                        <span>
                            {General.formatCurrency({
                                value: isNullSubtotal
                                    ? 0
                                    : items_print.grandTotal,
                            })}
                        </span>
                    </div>
                    <div className="bayar">
                        Dibayar:{" "}
                        <Show when={isNullPaymentAmount}>
                            <span>Tidak ada data</span>
                        </Show>
                        <Show when={!isNullPaymentAmount}>
                            <span>
                                {General.formatCurrency({
                                    value: isNullPaymentAmount
                                        ? 0
                                        : items_print.paymentAmount,
                                })}
                            </span>
                        </Show>
                    </div>
                    <div className="kembali">
                        Kembali:{" "}
                        <Show when={isNullPaymentReturn}>
                            <span>Tidak ada data</span>
                        </Show>
                        <Show when={!isNullPaymentReturn}>
                            <span>
                                {General.formatCurrency({
                                    value: isNullPaymentReturn
                                        ? 0
                                        : items_print.paymentReturn,
                                })}
                            </span>
                        </Show>
                    </div>
                </div>
            </div>
            <div
                className="strukFooter"
                dangerouslySetInnerHTML={{
                    __html: config?.posfooter
                        ? config?.posfooter
                        : "Terimakasih telah berbelanja di toko kami.",
                }}
            />
            {/* <div className="copyright">&copy; Powered by POZA - shiza.id</div> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    config: state.app.config,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(Struk);
