import APP_ACTION from "../actions/app";

const initialState = {
	loader: false,
	config: null,
	product_upc: {
		added: false,
		product: {},
	},
};

const REDUCER_APP = (prevState = initialState, action) => {
	switch (action.type) {
		case APP_ACTION.name.APP_LOADER:
			return {
				...prevState,
				loader: action.loader,
			};
		case APP_ACTION.name.APP_CONFIG:
			return {
				...prevState,
				config: action.config,
			};
		case APP_ACTION.name.APP_PRODUCT_UPC:
			return {
				...prevState,
				product_upc: action.product_upc,
			};
		default:
			return prevState;
	}
};

export default REDUCER_APP;
